import React from "react"
import Helmet from "react-helmet"
import config from "../../data/siteconfig"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import favicon from '../assets/img/logo.ico'
import 'font-awesome/css/font-awesome.min.css'
import "./index.sass"
import "../style/main.sass"

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props
    const title = this.props.title ? this.props.title + ' | ' + config.siteTitle : config.siteTitle
    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <link rel="icon" href={favicon} />
          <meta name="title" content={config.siteTitle} />
          <meta name="description" content={config.siteDescription} />
          <html lang="en" />
        </Helmet>
        <Header/>
        <div className="layout-container content">
          {children}
        </div>
        <Footer config={config} />
      </div>
    )
  }
}
