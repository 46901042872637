const config = {
  siteTitle: "trinityjchung", // Site title.
  siteTitleShort: "trinity", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "trinity's personal site", // Alternative site title for SEO.
  siteLogo: "/logos/logo-512.png", // Logo used for SEO and manifest.
  siteUrl: "https://trinityjchung.com", // Domain of your website without pathPrefix.
  pathPrefix: "", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "welcome to my corner of the internet", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteFBAppID: "", // FB Application ID for using app insights
  googleAnalyticsID: "", // GA tracking ID.
  appScriptExecUrl: "https://script.google.com/macros/s/AKfycbwGc46F3ANuEy6jm4ket7LoKhILU5WG2vNbjEn1d6QHRS3-IpIOqX0DxMPnZ5VGKNpohA/exec",
  postDefaultCategoryID: "post", // Default category for posts.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "MM/DD/YYYY", // Date format for display.
  postsPerPage: 4, // Amount of posts displayed per listing page.
  userName: "Trinity Chung", // Username to display in the author segment.
  userEmail: "trinityjchung@gmail.com", // Email used for RSS feed's author segment
  userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "", // User location to display in the author segment.
  userAvatar: "https://avatars1.githubusercontent.com/u/19580648?s=460&v=4", // User avatar to display in the author segment.
  userDescription: "", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "GitHub",
      url: "https://github.com/Milotrince",
      iconClassName: "fa fa-github"
    },
    {
      label: "Email",
      url: "mailto:trinityjchung@gmail.com",
      iconClassName: "fa fa-envelope"
    },
    {
      label: "LinkedIn",
      url: "https://www.linkedin.com/in/trinitychung/",
      iconClassName: "fa fa-linkedin"
    }
  ],
  copyright: "Copyright © 2020. Trinity Chung", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#0abeeb", // Used for setting manifest and progress theme colors.
  backgroundColor: "#fff0f0" // Used for setting manifest background color.
};

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
