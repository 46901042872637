import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import "./header.sass";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isNavActive: false };
  }

  toggleNav = () => {
    this.setState({ isNavActive: !this.state.isNavActive });
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            logo: file(name: { eq: "logo-192" }) {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 24, height: 24)
              }
            }
          }
        `}
        render={data => (
          <nav
            className="navbar horizontal-container"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="navbar-brand">
              <Link className="navbar-item navbar-brand-left" to="/">
                <GatsbyImage
                  className="logo-icon"
                  image={data.logo.childImageSharp.gatsbyImageData}
                />
                <span className="navbar-brand-text">
                  trinity<span className="alt-color">j</span>chung
                </span>
              </Link>

              <a
                role="button"
                aria-label="menu"
                className={`navbar-burger ${
                  this.state.isNavActive ? "is-active" : ""
                }`}
                data-target="navbar-menu"
                onClick={this.toggleNav}
              >
                {" "}
                <span /> <span /> <span />{" "}
              </a>
            </div>

            <div
              id="navbar-menu"
              className={`navbar-menu ${
                this.state.isNavActive ? "is-active" : ""
              }`}
            >
              <div className="navbar-end">
                <Link to="/research" className="navbar-item">
                  research
                </Link>
                <Link to="/play" className="navbar-item">
                  play
                </Link>
                <Link to="/reflections" className="navbar-item">
                  reflections
                </Link>
                <Link to="/bookshelf" className="navbar-item">
                  bookshelf
                </Link>

                <hr className="navbar-divider" />

                <div className="navbar-item">
                  <div className="buttons">
                    {/* <a className='button' href='https://ko-fi.com/milotrince' target='_blank'>
                  <span className="icon is-medium">
                    <i className="fa fa-coffee"/>
                  </span>
                  support me
                </a> */}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        )}
      />
    );
  }
}

export default Header;
